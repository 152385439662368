<template>
  <div>
    <div class="d-flex flex-column flex-md-row justify-space-between mb-4">
      <h1>
        Projects
      </h1>
      <v-chip-group
        v-model="selectedStatus"
        column
        @change="filterProjects"
      >
        <v-chip filter>
          Coming Soon
        </v-chip>
        <v-chip filter>
          Available Now
        </v-chip>
        <v-chip filter>
          Under Construction
        </v-chip>
        <v-chip filter>
          Generating Power
        </v-chip>
      </v-chip-group>
    </div>
    <v-row>
      <v-col v-for="project in projects" :key="project.address" xs="12" md="6" lg="4">
        <project-card :project="project"></project-card>
      </v-col>
      <v-col v-if="projectCount === 0">
        <v-alert
          type="info"
          color="secondary"
          prominent
          outlined
          text
        >
          <p class="mb-0">
            No projects found.
          </p>
        </v-alert>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ProjectCard from '@/components/ProjectCard.vue'

export default {
  components: { ProjectCard },
  data() {
    return {
      projects: [],
      projectCount: null,
      selectedStatus: null,
    }
  },
  mounted() {
    this.projects = this.$store.getters.projects
    this.projectCount = this.projects.length
  },
  methods: {
    filterProjects() {
      if (this.selectedStatus !== undefined) {
        this.projects = this.$store.getters.projectsStatus(this.selectedStatus + 1)
      } else {
        this.projects = this.$store.getters.projects
      }
      this.projectCount = this.projects.length
    },
  },
}
</script>
