<template>
  <v-card outlined>
    <v-img :src="project.image" height="200px" class="align-end">
      <v-chip
        class="status ms-5 mb-5 p-1"
        :color="status[project.status].color"
      >
        <span class="pe-1">
          {{ status[project.status].text }}
        </span>
      </v-chip>
    </v-img>

    <v-card-title>
      <router-link class="text-decoration-none" :to="{ name: 'project', params: { slug: project.slug }}">
        {{ project.title }}
      </router-link>
    </v-card-title>
    <v-card-subtitle>
      {{ project.capacity }} • {{ project.type }} • {{ project.region }}, {{ project.country }}
    </v-card-subtitle>

    <v-card-text>
      <p>{{ project.description }}</p>
    </v-card-text>

    <v-card-actions>
      <v-btn
        color="secondary"
        outlined
        rounded
        small
        :to="{ name: 'project', params: { slug: project.slug }}"
      >
        View Details
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: {
    project: {
      type: Object.new,
      default: {},
    },
  },
  setup() {
    return {
      status: {
        1: { text: 'Comming Soon', color: 'primary' },
        2: { text: 'Available Now', color: 'info' },
        3: { text: 'Under Construction', color: 'success' },
        4: { text: 'Generating Power', color: 'warning' },
      },
    }
  },
}
</script>

<style scoped>
  .status {
    opacity: 0.9;
  }
</style>
